<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import {ref, computed} from "vue";
import {useStore} from "vuex";

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  props : ['country'],
  setup(props){
    const store               = useStore();
    const items               = computed( () => store.getters.getCountryIndices(props.country));
    const indices1            = store.getters.getIndicesFromAxis(1, items);
    const indices2            = store.getters.getIndicesFromAxis(2, items);
    const indices3            = store.getters.getIndicesFromAxis(3, items);
    const unavaliableIndices1 = indices1.filter(d => d.value == null);
    const unavaliableIndices2 = indices2.filter(d => d.value == null);
    const unavaliableIndices3 = indices3.filter(d => d.value == null);

    const html = computed( () => {
      return store.getters.getSituation(props.country.id)
    });

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      html,
      show : ref(true),
      unavaliableIndices1,
      unavaliableIndices2,
      unavaliableIndices3
    }
  }
}
</script>

<template>
  <div>
    <section>
      <div class="row">
        <div class="col-12">
          <h1 class="mb-4 pb-4">Situación en <strong>{{country.name}}</strong></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-10 offset-sm-1" v-html="html"></div>
      </div>
    </section>
    <section class="od_noindex">
        <h3 class="mb-3"><strong>{{unavaliableIndices1.length + unavaliableIndices2.length + unavaliableIndices3.length}} indicadores no dispobibles</strong> en {{country.name}}</h3>
        <button v-on:click="show = !show" class="od_btn_noindex mb-4">
        {{show ? 'Ocultar' : 'Mostrar'}} indicadores no disponibles
      </button>
      <ol v-if="show" class="od_list_noindex">
        <li v-for="el in unavaliableIndices1" :key="el">{{el.name}}</li>
        <li v-for="el in unavaliableIndices2" :key="el">{{el.name}}</li>
        <li v-for="el in unavaliableIndices3" :key="el">{{el.name}}</li>
      </ol>
      </section>
  </div>
</template>